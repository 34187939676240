import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input, Button, } from "../../components/core";
// import NewModal from "./MessagesNewModal";
import utils from "../../functions/utils";
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";
// import ChatModal from "./MessagesChatModal";
import { useGlobals, useDispatch } from '../../app/context';
import "./Onboard1.scss";

const debug = 0;



export default function Page(){
    const [name, setName] = React.useState("demo@lumistry.com");
    const [nameError, setNameError] = React.useState(null);
    const onEmailChange = (e) => setName(e.target.value);
    const onKeyPress = (e) => ((e.key == 'Enter') && onSubmit());

    // on submit
    const onSubmit = () => {
        console.log("====== onSubmit: ");
    }


    return (
        <div className="is-centerstage">

            <div id="lds-login-form">
                {/* {entering && <Loader color="translucent" open duration={2000} onComplete={onLoaderComplete} />} */}

                <div id="lds-login-form-title">Login</div>
                
                <Input debug={debug} label="Email address" onChange={onEmailChange} onKeyPress={onKeyPress} value={name} error={nameError} />
                <Button.Primary label="Continue" onClick={onSubmit} /> 

                <div><a href="/login/forgot" id="lds-login-form-forgot">Forgot password?</a></div>
            </div>

        </div>
    );
}