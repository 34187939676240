import React from "react";
import {Sprite, Select, Alert} from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
import Modal from "../../components/containers/Modal";
import Layout from "../../components/containers/Layout";

import { useNavigate } from 'react-router-dom';
import { Button } from "@mui/material";
import "./Demos.scss";


const debug = 0;


// --- main --- //

function Item(props){
    let {to, url, onClick, label, } = props;
    const nav = useNavigate();

    if(!onClick) onClick = () => {
        utils.nav(nav, to);
    }

    const onDemoClick = () => {
        // const {name, } = item;
        // dispatch({call: "SET_DEMO", value: item.name, });
        // console.log("==== yoooooo: ", item);
        if(url) window.location = url;
        else utils.nav(nav, to);
    }

    // if(url) return <div><a href={url}>{label}</a></div>
    return <div className="lds-demos-link" onClick={() => onDemoClick()} >{label}</div>;
}

function Section(props){
    const {title, description, children} = props;

    return (
        <div className="lds-demos-section">
            {title && <h2>{title}</h2>}
            {description && <p>{description}</p>}
            {children}
        </div>
    );
}

export default function Screen(){
    // const dispatch = useDispatch();
    // const nav = useNavigate();
    // const {demos, } = useGlobals();



    // render
    return (
        <div id="lds-demos-page">

            <h1>Internal Demos</h1>
            <p>Click on the notiable screens and flows below.</p>

            <div id="lds-demos-list">

                <Section title="Screens and flows" description222="Notable screens and flows">
                    <Item to="/dashboard3" label="Dashboard - stacked lists" />
                    <Item to="/demos/onboard1" label="Onboarding - v1" />
                    <Item to="/app/push/refillready" label="Patient refill flow" />
                </Section>

                <Section title="Prototype" description222="Notable screens and flows">
                    <Item url="http://design.lumistry.com/" label="Design Demo" />
                    <Item url="http://demo.lumistry.com/" label="Sales Demo" />
                </Section>


                {/* 
                <Section title="Patient" description222="Notable screens and flows">
                </Section>

                <Section title="Pharmacy" description222="asdf">
                </Section> 
                */}

            </div>
        </div>


    );
}
