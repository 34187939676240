import React from "react";
import utils from "../../functions/utils";
import {Icon, Button, Alert} from "../core";
import { useGlobals, useDispatch } from '../../app/context';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Loader from "../../components/core/Loader";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import "./Modal.scss";



export default function Modal(props){
    var {label, children, onClick, id, className, type, size, onClose, bottom, buttons, onSubmit, message, noLoader} = props;
    const dispatch = useDispatch();

    const globals = useGlobals();
    const {showModal, showLoader, showModalLoader, modalComponent} = globals;

    // on close
    if(!onClose) onClose = (e) => {
        e.preventDefault();
        dispatch({call: "HIDE_MODAL"});
    }

    if(buttons){
        if(buttons.cancel === undefined) buttons.cancel = onClose;
        bottom = <Button.Group type="form" buttons={buttons} isForm={!!onSubmit} />
    }

    // render // ---=================== WIP
    const css = utils.classNames("lds-modal", className, type && `is-type-${type}`, size && `is-size-${size}`);
    return (
        <React.Fragment>
            {!noLoader && <Loader color="translucent" open duration={1000} />}
            <DialogTitle className="lds-modal-top-outer">
                <div className="lds-modal-top">
                    <b className="is-thicktext-md">{label}</b>
                    <div className="lds-modal-closer" onClick={onClose}><Icon type="x" /></div>
                </div>
            </DialogTitle>
            <DialogContent className="lds-modal-content-outer" dividers={false} style={{width: 800, }}>
                <div className="lds-modal-content">
                    {message && <Alert message={message} />}
                    {children}
                </div>
            </DialogContent>
            {bottom && <DialogActions><div className="lds-modal-bottom">{bottom}</div></DialogActions>}
        </React.Fragment>
    );
}


    // // render
    // const css = utils.classNames("lds-modal", className, type && `is-type-${type}`, size && `is-size-${size}`);
    // return (
    //     <form onSubmit={onSubmit} style={{"position": "relative"}} className={css} id="modal111" method="GET" action="?">

    //         <Backdrop id="lds-modal-backdrop" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 , }} open={showModalLoader}>
    //           <CircularProgress color="inherit" />
    //         </Backdrop>

    //         <div className="lds-modal-top">
    //             <b className="is-thicktext-md">{label}</b>
    //             <div className="lds-modal-closer" onClick={onClose}><Icon type="x" /></div>
    //         </div>
    //         <div className="lds-modal-content">
    //             {message && <Alert message={message} />}
    //             {children}
    //         </div>
    //         {bottom && <div className="lds-modal-bottom">{bottom}</div>}
    //     </form>
    // );