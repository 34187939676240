import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Mobile from "../../components/containers/Mobile";
import NotificationModal from "../../screens/general/NotificationModal";
import { useGlobals, useDispatch } from '../../app/context';
import Loader from "../../components/core/Loader";

const debug = 0;

export default function Page(props){
    // const {notification} = props;
    const dispatch = useDispatch();
    const {showModal, } = useGlobals();
    const params = useParams();
    const location = useLocation();
    const {notification} = location.state || {}; // params

    // init
    React.useEffect(() => {
        if(!notification || showModal) return;

        setTimeout(() => {
            dispatch({call: "SHOW_MODAL", component: <NotificationModal />});
        }, 500);
        
    }, []);

    return (
        <Mobile.Wrapper type="home" showNotification222={true} label="Martha">

            {/* <Loader type="preload" debug={0} style={{top: 0, height: 440, }} /> */}

            <Sprite.Container id="lds-app-home">
                {/* <Sprite.LikeText debug={debug} style={{top: 40, width: '100%', height: 80, }} /> */}
                <Sprite.Button debug={debug} to="/app/meds/" style={{top: 20, width: '100%', height: 70, }} />
                <Sprite.Button debug={debug} to="/app/messages/" style={{top: 90, width: '100%', height: 90, }} />
                <Sprite.Button debug={debug} to="/app/appts/" style={{top: 180, width: '100%', height: 90, }} />
                <Sprite.Button debug={debug} to="/app/account/people/" style={{top: 270, width: '100%', height: 90, }} />
                <Sprite.Button debug={debug} to="/app/account/" style={{top: 360, width: '100%', height: 90, }} />
            </Sprite.Container>
        </Mobile.Wrapper>
    );
}
