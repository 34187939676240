import utils from "../../functions/utils";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate, Link } from 'react-router-dom';
import Sprite from "../../components/core/Sprite";
import {Input, Icon} from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import Menu from "../../components/lists/Menu";
// import DemosModal from "../../demos/ui/DemosModal";
import "./TopNav.scss";

const debug = 0;

// {divider: true, },
// {label: "Logout", to: "/logout", },


// {divider: true, },

// context menu
// const contextItems = [
//     {id: 1, label: "Find a location",  icon: "search", }, 
//     {divider: true, },
//     {id: 1, label: "Town Pharmacy HQ",  icon: "globe", isBrand: true, }, 
//     {id: 1, label: "Cameron's HQ",  icon: "globe", isBrand: true, }, 
//     {lid: 1, abel: "Cameron’s - South Lamar",  },
//     {id: 1, label: "Town Pharmacy - Congress Ave.",  },
//     {label: "Town Pharmacy - UT Campus",  },
//     {label: "Comeron’s - Riverside",  },
//     {label: "Comeron’s - North Austin",   },
// ];



// components
function Tab({label, value, tab, number}){
    const css = utils.classNames("lds-topnav-tab is-text-md", (tab === value) && "is-selected");
    // return <div className={css}>{label}{number && <div className="lds-topnav-number">{number}</div>}</div>;
    return (
        <Link to={`/${value}`} className={css}>
            {label}{number && <div className="lds-topnav-number">{number}</div>}
        </Link>

    );
}


export default function TopNav(props){
    const dispatch = useDispatch();
    const {isSales, domain, } = useGlobals();
    const location = useLocation();
    const nav = useNavigate();
    const {isAuth, context, contextItems} = useGlobals();
    const parts = location?.pathname?.split('/');
    const tab = parts[1]; //  "messages";

    // more
    // const onMore = () => dispatch({call: "SHOW_MODAL", component: <DemosModal />});

    // secondary
    const items = [
        {label: "Automated actions", to: "/auto", },
        {label: "Videos", to: "/videos", }, 
        {label: "Wellness classes", to: "/classes", }, 
        {label: "Refill submissions", to: "/refills", },
        {label: "Audit trail", to: "/audit", },
        {label: "Vow IVR", to: "/vow", },
        {label: "Help and support", to: "/help", },
        {divider: true, },
        {label: "Internal Demos", to: "/demos", icon: "lumistry" },
    ];

    // click logout
    const onClickLogout = () => {
        utils.logout(dispatch, nav);
    }
    const onClockContext = (item) => {
        dispatch({call: "SET_CONTEXT", item,  }); // location.pathname
        const url = location?.pathname || "/";
        utils.delayRedirect(dispatch, nav, url, 1000);
    }

    // avatar menu
    const avatarItems = [
        {label: "Profile settings", to: "/profile", icon: "user", }, 
        {label: "Switch users", to: "/logout", icon: "recurring", },
        {label: "Sign out", to222: "/logout", icon: "external", onClick: onClickLogout, }, // "/logout"
    ];
    
    // auth
    if(!isAuth) return (
        <div className="lds-topnav-noauth">
            <div className="lds-topnav-noauth-left" />
            <div className="lds-topnav-noauth-right" />
        </div>
    );


    // render
    const css = utils.classNames("lds-topnav", context.isBrand && "is-brand", );
    return (
        <div className={css}>
        {isSales && domain === 'localhost' && <div className="lds-topnav-demo">SALES</div>}
            <div className="lds-topnav-logo">
                <Link to="/" style={{border: 'red 0 solid', width: 80, height: 70, display: "block", }}><i className="lds-graphic is-logo" /></Link>
                
            </div>
            <Tab label="Messages" tab={tab} value="messages" />
            <Tab label="Tasks" tab={tab} value="tasks" />
            <Tab label="Schedule" tab={tab} value="schedule" number={3} />
            <Tab label="Patients" tab={tab} value="patients" />
            <Tab label="Forms" tab={tab} value="forms" />
            <Tab label="Marketing" tab={tab} value="marketing" />
            {/* <Tab label="Reports" tab={tab} value="reports" /> */}

            <div className="lds-topnav-right">
                <Menu items={contextItems} onClick={onClockContext} position="top-right" width={250} style={{top: 16, left: 'auto', right: 224, position: "absolute", }}>
                    <Sprite.Button className="lds-topnav-context" debug={debug} style={{width: 250, height: 46, maxWidth: 250, }}>
                        {context.isBrand && <Icon type="globe-white"/>}
                        <span>{context.label}</span>
                    </Sprite.Button>
                </Menu>
                <Sprite.Button to222="/logout" abs onClick={onClickLogout} debug={debug} style={{width: 40, height: 46, top: 16, left: 'auto', right: 150,  }} />
                <Sprite.Button to="/settings" abs debug={debug} style={{width: 40, height: 46, top: 16, left: 'auto', right: 106,  }} />
                <Menu items={items} position="top-right" style={{top: 16, left: 'auto', right: 64, position: "absolute", }}>
                    <Sprite.Button debug={debug} style={{width: 40, height: 46, }} />
                </Menu>
                <Menu items={avatarItems} position="top-right" style={{top: 16, left: 'auto', right: 16, position: "absolute", }}>
                    <Sprite.Button debug={debug} style={{width: 40, height: 46, }} />
                </Menu>
                {/* <Input.SelectText name="type" label="Type" options={["General", "two", "three"]} style={{top: 40, left: 0, width: 300, }} /> */}
            </div>
        </div>
    );
}