import React, { useEffect } from "react";
import {Sprite, Icon, Text, Input} from "../../components/core";
// import NewModal from "./MessagesNewModal";
import utils from "../../functions/utils";
import Loader from "../../components/core/Loader";
import Layout from "../../components/containers/Layout";
// import ChatModal from "./MessagesChatModal";
import { useGlobals, useDispatch } from '../../app/context';
// import "./Onboard1.scss";

const debug = 0;



export default function Page(){

    const onClickCTA = () => {};


    // render
    return (
        <React.Fragment>

            <h1>Onboarding 2</h1>

            {/* {false && <Loader type="preload" duration={2000} onComplete={onLoaderComplete} style={{top: 70, left: 280, width: 1020, height: 680,  }} />} */}

            {/* <Layout.WithPanel showPanel={false}>
                <Layout.Sidenav spriteId="lds-marketing-sidenav">

                    <Layout.CTA onClick={onClickCTA} debug={debug} />

                </Layout.Sidenav>
                <Layout.Content>

                    <Sprite.Container id="lds-marketing-library">

                        <Input.Toggle on={true} sprite style={{top: 167, left: 363, }} />
                        <Input.Toggle on={true} sprite style={{top: 307, left: 363, }} />
                        <Input.Toggle on={true} sprite style={{top: 167, left: 871, }} />

                    </Sprite.Container>

                </Layout.Content>
            </Layout.WithPanel> */}




        </React.Fragment>

    )
}