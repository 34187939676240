import React from "react";
import {Sprite, Select, Alert} from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
import Modal from "../../components/containers/Modal";
import MessagesNewModal from "./MessagesNewModal";

const debug = 0;


function Nav(props){
    const {type, onClick, } = props;
    if(!onClick) onClick = () => {};

    // render
    return (
        <Sprite.Container className="lds-messages-preview-nav" id={`lds-messages-preview-${type}-nav`}>

            <Sprite.Button debug={debug} onClick={() => onClick('chat')} style={{top: 10, left: 210, width: 70, height: 50, }} />
            <Sprite.Button debug={debug} onClick={() => onClick('sms')} style={{top: 10, left: 280, width: 70, height: 50, }} />
            <Sprite.Button debug={debug} onClick={() => onClick('email')} style={{top: 10, left: 350, width: 70, height: 50, }} />
            <Sprite.Button debug={debug} onClick={() => onClick('voice')} style={{top: 10, left: 420, width: 70, height: 50, }} />

        </Sprite.Container>
    );
}


export default function Modal2(props){
    const dispatch = useDispatch();
    const [formError, setFormError] = React.useState(null);
    const [mode, setMode] = React.useState('chat');


    const onBack = () => dispatch({call: "SHOW_MODAL", component: <MessagesNewModal />});


    // render
    return (
        <Modal size="lg" label="Preview" buttons={{back: onBack, cancel: false, }} message={formError}>
            
            <Nav type={mode} onClick={(type) => setMode(type)} />
            
            <Sprite.Container id={`lds-messages-preview-${mode}`}>
                 

            </Sprite.Container>

        </Modal>
    );
}

