import React from "react";
import {Sprite, Select, Alert} from "../../components/core";
import { useGlobals, useDispatch } from '../../app/context';
import utils from "../../functions/utils";
import Modal from "../../components/containers/Modal";
import MessagesPreviewModal from "./MessagesPreviewModal";
import Loader from "../../components/core/Loader";


const debug = 0;


export default function Modal2(props){
    const dispatch = useDispatch();
    const [formError, setFormError] = React.useState(null);
    const [closing, setClosing] = React.useState(false);

    const onLoaderComplete = (e) => {
        dispatch({call: "SET_MESSAGE_ADDED", });
        dispatch({call: "HIDE_MODAL"});
    }

    const onOK = () => {
        setClosing(true);
    }

    const onPreview = () => dispatch({call: "SHOW_MODAL", component: <MessagesPreviewModal />});

    return (
        <Modal noLoader size="lg" label="New Bulk Message" buttons={{ok: onOK, }} message={formError}>

            {closing && <Loader type="preload" color="translucent" duration={1000} onComplete={onLoaderComplete} />}
            
            <Sprite.Container id="lds-messages-new-modal2">

                 
                <Sprite.Button debug={debug} onClick={onPreview} style={{top: 230, left: 670, width: 100, height: 40, }} />

                {/* <Sprite.Button debug={debug} style={{top: 0, left: 0, width: 220, height: 30, }} className="is-cursor-text" />
                <Sprite.Button debug={debug} style={{top: 40, left: 0, width: 220, height: 400, }} />
                <Sprite.Button debug={debug} style={{top: 0, left: 240, width: 530, height: 30, }} className="is-cursor-text" />
                <Sprite.Button debug={debug} style={{top: 0, left: 240, width: 530, height: 50, }} className="is-cursor-text" />
                <Sprite.Button debug={debug} style={{top: 80, left: 240, width: 530, height: 50, }} className="is-cursor-text" />
                <Sprite.Button debug={debug} style={{top: 160, left: 240, width: 530, height: 190, }} className="is-cursor-text" /> */}

            </Sprite.Container>
        </Modal>
    );
}

